import { Button, Form, Input } from "antd";
import NotifyUser from "../utils/NotifyUser";
import { axiosInstance } from "../utils/axiosInstance";
import { GATEWAY_URL } from "../utils/constants";
import "./unAuthorized.scss";
import { useState } from "react";
import axios from "axios";
import logo from "./Logo.svg";
import signup from "./Frame 1171276149.svg";
import tabview from "./tab view.svg";
import { Link } from "react-router-dom";

const Signup = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onFinish = async (values) => {
    setIsSubmitted(true);
    try {
      await axiosInstance.post(`${GATEWAY_URL}/user`, values).then(async () => {
        NotifyUser({
          apiStatus: 200,
          content: "Check your email for the signup link!",
          type: "success",
        });
      });
    } catch (error) {
      setIsSubmitted(false);
      console.log(error);
      NotifyUser({ apiStatus: 500, content: "Please retry" });
    }
  };

  return (
    <div className="login-signup-container">
      <div className="login-signup-form">
        <div className="form-container">
          <div className="company-info">
            <img src={logo} alt="Logo" />
          </div>
          <div className="signup-form-container">
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              requiredMark={false}
            >
              <div className="form-name-div">
                <Form.Item
                  label={
                    <span>
                      First Name<span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                  ]}
                  style={{
                    // width: "270.01px",
                    height: "67.94px",
                    top: "264.87px",
                    left: "141px",
                  }}
                >
                  <Input placeholder="First name" size="large" />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Last Name<span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="last_name"
                  rules={[
                    { required: true, message: "Please input your Last Name!" },
                  ]}
                  style={{
                    // width: "270.01px",
                    height: "67.94px",
                    top: "264.87px",
                    left: "411.99px",
                  }}
                >
                  <Input placeholder="Last name" size="large" />
                </Form.Item>
              </div>
              <Form.Item
                label={
                  <span>
                    Email Id<span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="email"
                rules={[
                  { required: true, message: "Please input your Email Id" },
                  { type: "email", message: "Please enter a valid Email Id" },
                ]}
              >
                <Input placeholder="Email id" size="large" />
              </Form.Item>
              <Form.Item
                label={<span>Company Name</span>}
                name="orgName"
                rules={[
                  {
                    required: true,
                    message: "Please input your Company Name!",
                  },
                ]}
              >
                <Input placeholder="Company name" size="large" />
              </Form.Item>
              <div className="Continue-button">
                <Form.Item>
                  <Button
                    disabled={isSubmitted}
                    style={{
                      height: "52px",
                      width: "202px",
                      padding: "10px",
                      marginTop: "10px",
                      borderRadius: "10px",
                    }}
                    className={!isSubmitted ? "submit-btn" : "ant-btn-disabled"}
                    htmlType="submit"
                  >
                    {!isSubmitted ? "Continue" : "Check your email"}
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <div className="trems-text-div">
              <p>
                By signing up, you agree to Kognics’{" "}
                <Link
                  className="terms-link"
                  to="https://kognics.ai/terms-of-use/"
                  target="_blank"
                >
                  Terms of Service
                </Link>{" "}
                &{" "}
                <Link
                  className="terms-link"
                  to="https://kognics.ai/privacy-policy/"
                  target="_blank"
                >
                  {" "}
                  Privacy Policy
                </Link>{" "}
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="right-img">
        <img src={signup} alt="signup" />
      </div>
    </div>
  );
};

export default Signup;

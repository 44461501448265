import loadingGif from "./loader.gif";
import "./loader.scss";
const Loader = () => {
  return (
    <div className="loader-overlay">
      <img src={loadingGif} style={{ width: "600px" }} alt="Loading" />
    </div>
  );
};
export default Loader;

import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, message } from "antd";
import "./unAuthorized.scss";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { GATEWAY_URL } from "../utils/constants.js";
import moment from "moment";
import logo from "./Logo.svg";
import Loader from "./Loader/index.js";
function ForgotPassword() {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const usernameParam = new URLSearchParams(search).get("username");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [isloading, setloading] = useState(false);

  const validatePassword = (value) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
    if (!passwordRegex.test(value)) {
      setPasswordValidationMsg(
        "Password should contain at least 8 characters, one lowercase letter, one uppercase letter, one number and one special character (Please do not use Hashtag in the Password)."
      );
    } else {
      setPasswordValidationMsg("");
    }
  };

  const verifyToken = async () => {
    try {
      setloading(true);
      await axios.get(
        `https://backend.kognics.com/sign-up/verify-token?token=${token}`,
        {
          withCredentials: true,
        }
      );
      setIsTokenVerified(true);
    } catch (error) {
      console.error("Token verification failed:", error);
      setIsTokenVerified(false); // Ensure token is marked as invalid
    } finally {
      setloading(false); // Always stop loading, whether request succeeds or fails
    }
  };
  useEffect(() => {
    verifyToken();
  }, []);

  const onForgotPasswordFormSubmit = async () => {
    try {
      await axios
        .put(
          `https://backend.kognics.com/sign-up/forgot-password?token=${token}`,
          {
            password: password,
            confirmPassword: confirmPassword,
          }
        )
        .then(() => {
          message.success(
            "Password Successfully Reset",
            [1.5],
            () => (window.location.href = " https://app.kognics.com/login")
          );
        });
    } catch (error) {
      console.log(error);
      message.error("Reset Password failed!!", [1.5]);
    }
  };

  return (
    <div>
      {isloading ? (
        <Loader />
      ) : (
        <div>
          {isTokenVerified ? (
            <Card className="center-card">
              <img src={logo} alt="logo" />

              <h2>Reset Password</h2>
              <p>Enter your new password below to reset your account.</p>
              <div className="Inputs">
                <Form
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  onFinish={onForgotPasswordFormSubmit}
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    initialValue={usernameParam}
                  >
                    <Input
                      readOnly
                      style={{ fontWeight: "bold", border: "none" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        pattern:
                          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message:
                          "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character (Please do not use Hashtag in the Password).",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Enter password"
                      size="large"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            setSubmitButtonDisable(false);
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                      size="large"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="submit-btn"
                      htmlType="submit"
                      disabled={submitButtonDisable}
                    >
                      Reset Password
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Card>
          ) : (
            <h1>Token Expired Resend Email</h1>
          )}
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
